<template>
  <div class="mt-12">
    <div class="card bg-white rounded-xl p-4 ">
      <div class="relative">
        <div class="card top_primary_card bg-primary font-bold text-white p-4 rounded-xl absolute -top-11 w-full">
          Job Types ({{jobTypeList.length}})
        </div>
      </div>
      <div class="mt-8">
        <div class="flex flex-wrap justify-between items-center">
          <div class="w-72 mb-4">
          </div>
          <div class="mb-4">
            <Button @buttonAction="addNewCust(false)" :btnSize="'large'" :textColor="'white'" :btnColor="'primary'" :btnText="'Add Job Type'"/>
          </div>
        </div>
      </div>
      <div>
        <div>
          <div style="overflow-y: auto; white-space: nowrap;" class="" v-if="jobTypeList.length > 0">
          <div v-for="(data, index) in jobTypeList" :key="index"  class="cust_card m-2  heading-3 border-b border-gray1  bg-white  text-sm px-3 py-3 items-center hover:bg-gray1 cursor-pointer" @click="openDetail(data.jobTypeId, data)">
            <div class="flex items-center justify-between">
              <p class="text-gray4 heading-5" style="font-weight: bold;">{{data.jobTypeName}}
                <span class="text-gray4 heading-6" style="font-weight: normal;">({{data.jobTypeShortCode}})</span>
                <span class="text-gray4 heading-6" style="font-weight: normal;" v-if="data.IsDefault"> - Is Default </span>
              </p>
              <div :style="`width:20px;height:20px;background:` +data.jobTypeColor+ `;`"></div>
            </div>
            <div class="flex items-center justify-between" v-if="data.jobCategoryName">
              <p class="text-gray4 pt-1 heading-6" style="white-space: break-spaces;">Category : {{data.jobCategoryName}}
              </p>
            </div>
            <div class="flex items-center justify-between" v-else>
              <p class="text-gray4 pt-1 heading-6" style="white-space: break-spaces;">No Category linked
              </p>
            </div>
            <div v-if="data.jobCheckListArray">
              <span class="text-gray4" style="font-size:16px">Check List:</span>
                <div v-for="(item, index) in data.jobCheckListArray" :key="index" class="chip px-2 mx-1">
                  <span class="text-gray4" style="font-size:12px"> {{item.checkLiTitle}}</span>
                </div>
              </div>
          </div>
        </div>
        <div v-else>
          <NoRecords :alertMessage="`No records found.`" />
        </div>
        </div>
      </div>
    </div>
    <add-edit-tax v-if="addJobType" :jobTypeId="selectedCatId" :jobTypeDetail="selectedDetail" :currentDefaultJT="currentDefaultJobType" />
   </div>
</template>
<script>
import NoRecords from '@/View/components/noRecords.vue'
import MyJobApp from '@/api/MyJobApp.js'
import Button from '@/View/components/globalButton.vue'
import AddEditTax from './addEditJobType.vue'
export default {
  components: {
    NoRecords,
    Button,
    AddEditTax
  },
  data() {
    return {
      jobTypeList: [],
      addJobType: false,
      selectedCatId: 0,
      currentDefaultJobType: '',
      selectedDetail: {}
    };
  },
  created() {},
  mounted() {
    document.body.style = 'overflow: visible;'
    this.callJobTypeListAPI()
    this.$root.$on("addNewJobTypeComponennt", (response) => {
      console.log(response);
      if (response) {
        this.callJobTypeListAPI()
        this.addJobType = false
      }
      this.addJobType = false
    });
  },
  beforeDestroy() {
    this.$root.$off('addNewJobTypeComponennt')
  },
  methods: {
    openDetail (jobTypeId, data) {
      this.selectedCatId = jobTypeId
      this.selectedDetail = data
      this.addJobType= true
    },
    addNewCust () {
      this.selectedCatId = 0
      this.selectedDetail = {}
      this.addJobType= true
    },
    callJobTypeListAPI () {
    this.jobTypeList = []
    this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
      MyJobApp.GetJobTypeList(
          response => {
            if (response.Data !== null) {
              response.Data.forEach(element => {
                if (element.IsDefault) {
                  this.currentDefaultJobType = element.jobTypeName
                }
                if (element.jobCheckList !== null) {
                  element.jobCheckListArray = JSON.parse(element.jobCheckList)
                } else {
                  element.jobCheckListArray = []
                }
                this.jobTypeList.push(element)
              });
            } else {
              this.jobTypeList = []
            }
            console.log('jobTypeList', this.jobTypeList)
            this.$store.dispatch('SetLoader', {status: false, message: ''})
          },
          () => {
            this.$store.dispatch('SetLoader', {status: false, message: ''})
          }
        )
    }
  },
};
</script>
<style scoped>
.lead_name_col {
  min-width: 280px !important;
  width: 280px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
.chip {
  display: inline-block;
  background-color: #dfdede;
  border-radius: 10px;
}
</style>