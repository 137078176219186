<template>
  <div class="popup_overlay px-4">
  <div class="cust_card  bg-white" style="width: 600px;max-height: 85%; min-height: 85%; overflow: auto;">
    <div class="">
      <div class="bg-primary flex justify-between p-3">
        <div class=" font-medium text-white heading-3">
          {{jobTypeId > 0 ? 'Update Job Type' : 'Add New Job Type'}}
        </div>
        <div v-if="jobTypeId > 0">
          <div class=" text-error heading-5 cursor-pointer bg-white px-2.5 py-1 rounded-xl" @click="checkJobTypeLinkORNot()"> 
            <i class="fa-solid fa-trash"></i>
          </div>
        </div>
      </div>
      <div style="" class="">
        <div class="p-4">
         <div class="mb-4">
              <TextField
              :inputId="'34324testInput'"
              :textMaxlength="50"
              :showcharLimit="true"
              :inputext="saveJobTypeObject.jobTypeName"
              :placholderText="`Job Type Name`"
              :lableText="'Job Type Name*'"
              :autoFocus="false"
              @inputChangeAction="(data) => saveJobTypeObject.jobTypeName = data"  />
          </div>
          <div class="mb-4">
            <TextField
              :inputId="'3453456777testInput'"
              :textMaxlength="5"
              :showcharLimit="true"
              :inputext="saveJobTypeObject.jobTypeShortCode"
              :placholderText="`Short Code`"
              :lableText="'Short Code*'"
              :autoFocus="false"
              @inputChangeAction="(data) => saveJobTypeObject.jobTypeShortCode = data"  />
          </div>
          <div class="mb-4 flex items-center">
              <p class="label_16 pr-4">Color</p>
              <button @click="showColorPicker = true" style="height: 28px; width:40px" :style="`background: ${saveJobTypeObject.jobTypeColor}`"  class="card border rounded"></button>
            </div>
          <div class="mb-4 flex items-center">
            <div>
              <span class="label_16">Hours: </span>
              <button class="text-white bg-primary rounded-lg mr-2 ml-2" style="height: 30px; width:40px;" @click="decrementHours">-</button>
              <span class="pl-4 pr-4 border border-solid border-gray-400 py-1">{{ hours }}</span>
              <button class="text-white bg-primary rounded-lg mr-2 ml-2" style="height: 30px; width:40px;" @click="incrementHours">+</button>
            </div>
            <div class="pl-2">
              <span class="label_16">Minutes: </span>
              <button class="text-white bg-primary rounded-lg mr-2 ml-2" style="height: 30px; width:40px;" @click="decrementMinutes">-</button>
              <span class="pl-4 pr-4 border border-solid border-gray-400 py-1">{{ formattedMinutes }}</span>
              <button class="text-white bg-primary rounded-lg mr-2 ml-2" style="height: 30px; width:40px;" @click="incrementMinutes">+</button>
            </div>
          </div>
           <div class="mb-4">
              <CheckBoxInput
              :checkValue="saveJobTypeObject.isDefault"
              :label="`Set as Default (Current Default is ${currentDefaultJT})`"
              @setIsActive="saveJobTypeObject.isDefault = !saveJobTypeObject.isDefault"  />
          </div>
          <div class="mb-4">
                <Dropdown
                  :disabled="false"
                  :inputext="searchForCategory" 
                  :inputId="'select_job_Categsd'"
                  :lableText="'Select Job Type Category'"
                  :placholderText="`Select Job Type Category`"
                  :autoFocus="false"
                  :showArrow="true"
                  :setReadOnly="false"
                  :searchCallApi="true"
                  :showPlus="false"
                  :keyName="'categoryName'"
                  :listId="'jobCategoryId'"
                  :items="jobTypeCategoreis"
                  @selectNewForVal="selectedCategory"
                  />
          </div>
          <div class="mb-4" style="">
              <div class="flex justify-between mb-4">
                <h2 class="label_18 font-semibold">Checklist</h2>
                <button
                   v-if="checklist.length === 0"
                  @click="addItem"
                  class="text-white bg-primary rounded-lg px-2"
                  style="height: 34px;"
                >
                  Add CheckList +
                </button>
              </div>

              <div :style="checklist.length > 5 ? `height:400px;overflow:auto` : ''">
                <div v-for="(item, index) in checklist" :key="index" class="flex items-center space-x-4 mb-2">
                  <div class=" text-error pt-4 cursor-pointer bg-white" @click="removeItem(index)"> 
                    <i class="fa-solid fa-trash-can" style="font-size: 24px"></i>
                  </div>
                  <div style="width: 420px;">
                    <TextField
                    :inputId="'dsfsdfsdf324523'+index"
                    :textMaxlength="50"
                    :showcharLimit="true"
                    :inputext="item.checkLiTitle"
                    :placholderText="`Checklist title`"
                    :lableText="'Checklist title'"
                    :autoFocus="false"
                    @inputChangeAction="(data) => item.checkLiTitle = data"  />
                  </div>
                  <div v-if="index === checklist.length - 1 && item.showPlus" class=" text-primary pt-4 cursor-pointer bg-white" @click="addItem()"> 
                    <i class="fa-solid fa-circle-plus" style="font-size: 26px"></i>
                  </div>
                </div>
              </div>
          </div>
        </div>
      </div>
      <div class=" sticky bottom-0 bg-white w-full flex justify-end p-4">
        <div class="text-rigth flex gap-2">
          <Button :btnSize="'medium'" :textColor="'text1'" :btnColor="'gray1'" :btnText="'Cancel'" @buttonAction="discard()"/>
          <Button :btnSize="'medium'" :textColor="'white'" :btnColor="'success'" :btnText="'Save'" :disabled="saveJobTypeObject.jobTypeName.trim() !== '' && saveJobTypeObject.jobTypeShortCode.trim()  ? false : true" @buttonAction="SaveJobTypeData()"/>
        </div>
      </div>
    </div>
  </div>
  <div class="popup_overlay relative px-4" v-if="showColorPicker">
       <div style="width: 500px;" class="custom_dialog rounded-xl h-min p-4">
        <div class="text-center">
            <p class="text-gray4">Select Color Code</p>
        </div>
        <p class="divider"></p>
        <div class="flex flex-col items-center justify-center">
          <div class="grid w-full max-w-xl grid-cols-12 mx-auto m-4">
            <div v-for="(color, index) in colorList" class="p-2 " :key="index">
              <span @click="addedColor(color)" :class="color.isSelected ? 'border border-text1 -translate-y-2 scale-125 transform transition' : 'border border-gray2'" class="text-text2 card rounded cursor-pointer h-8 w-8 flex" :style="'background-color:' + color.code"></span>
            </div>
          </div>
        </div>
        <p class="divider"></p>
        <div class="flex gap-2 justify-end mt-4">
          <Button :btnSize="'small'" :textColor="'text1'" :btnColor="'gray1'" :btnText="'Cancel'" @buttonAction="showColorPicker = false"/>
        </div>
      </div>
    </div>
  <ConfirmBox v-if="removeConfirm" :message="'Once deleted cannot undone.'" :title="'Are you sure delete tool?'" />
  <div v-if="alernateRemoveConfirm" class="popup_overlay px-4">
    <div class="custom_dialog_with_overflow" id="itemdialogs" style="width: 600px;max-height: 85%;">
      <div class="pt-2 pl-4">
        <p class="pa-0 ma-0 font-bold heading-3">Existing jobs to assigne to</p>
        <p class="pa-0 ma-0 heading-5">Please select the alternate job type.</p>
        </div>
      <div class="relative bg-white">
        <div>
          <div class="p-4">
          <div class="mb-1">
                <Dropdown
                  :disabled="false"
                  :inputext="searchForJobType" 
                  :inputId="'select_job_type_category'"
                  :lableText="'Select Job Type'"
                  :placholderText="`Select Job Type`"
                  :autoFocus="false"
                  :showArrow="true"
                  :setReadOnly="false"
                  :searchCallApi="true"
                  :showPlus="false"
                  :keyName="'jobTypeName'"
                  :listId="'jobTypeId'"
                  :items="jobTypeList"
                  @selectNewForVal="selectedJobType"
                  />
          </div>
          <div class="pa-0 ma-0 mt-4">
            <p class="pa-0 ma-0 heading-5 font-bold">Once deleted cannot undone.</p>
            <div class="flex">
              <p class="pa-0 ma-0 heading-5 pr-2 text-error">Are you sure delete tool?*</p>
              <CheckBoxInput
                :checkValue="deleteJobTypeCheckBox"
                :label="deleteJobTypeCheckBox ? 'Yes' : 'No'"
                @setIsActive="deleteJobTypeCheckBox = !deleteJobTypeCheckBox"  />
              </div>
          </div>
          </div>
        </div>
        <div class=" sticky -bottom-1 bg-white w-full flex justify-end pr-3 pt-2">
          <div class="text-rigth flex gap-2 mb-3">
            <Button :btnSize="'medium'" :textColor="'text1'" :btnColor="'gray1'" :btnText="'Cancel'" @buttonAction="alernateRemoveConfirm = false"/>
            <Button :btnSize="'medium'" :textColor="'white'" :btnColor="'success'" :btnText="'Ok'" :disabled="newjobTypeId > 0 && deleteJobTypeCheckBox === true ? false : true" @buttonAction="deleteApi()"/>
          </div>
        </div>
      </div>
  </div>
</div>
</div>
</template>
<script>
import ConfirmBox from '@/View/components/ConfirmBox.vue'
import Button from '@/View/components/globalButton.vue'
import TextField from '@/View/components/textfield.vue'
import Dropdown from '@/View/components/dropdown.vue'
import CheckBoxInput from '@/View/components/checkBoxInput.vue'
import MyJobApp from '@/api/MyJobApp.js'
export default {
props: ['jobTypeId', 'jobTypeDetail','currentDefaultJT'],
components: {
  ConfirmBox,
  Button,
  TextField,
  Dropdown,
  CheckBoxInput,
},
data () {
  return {
    deleteJobTypeCheckBox: false,
    removeConfirm: false,
    alernateRemoveConfirm: false,
    saveJobTypeObject: {
      jobTypeId: 0,    
      jobTypeName: '', 
      jobCategoryId: 0,    
      jobCategoryName: '', 
      jobTypeShortCode: '', 
      jobTypeColor: '#7289DA', 
      typeEstimationMinutes: 0,    
      jobCheckList: '', 
      displayOrder: 0,    
      IsDefault: false,   
      isActive: true   ,
      organizationDetailId: 0 
    },
    showColorPicker: false,
    colorList: [
        {code: '#7289DA', isSelected: false},
        {code: '#FF7200', isSelected: false},
        {code: '#0e7484', isSelected: false},
        {code: '#E91E63', isSelected: false},
        {code: '#1AB426', isSelected: false},
        {code: '#F08080', isSelected: false},
        {code: '#FFC0CB', isSelected: false},
        {code: '#FF1493', isSelected: false},
        {code: '#FF6347', isSelected: false},
        {code: '#FFD700', isSelected: false},
        {code: '#FFFACD', isSelected: false},
        {code: '#FFE4B5', isSelected: false},
        {code: '#4B0082', isSelected: false},
        {code: '#2E8B57', isSelected: false},
        {code: '#7FFF00', isSelected: false},
        {code: '#00FFFF', isSelected: false},
        {code: '#4682B4', isSelected: false},
        {code: '#8d2345', isSelected: false},
        {code: '#008080', isSelected: false},
        {code: '#191970', isSelected: false},
        {code: '#D2691E', isSelected: false},
        {code: '#1976D6', isSelected: false},
        {code: '#409F26', isSelected: false},
        {code: '#C8E6FD', isSelected: false},
        {code: '#2D2D2D', isSelected: false},
        {code: '#949494', isSelected: false},
        {code: '#3f38ef', isSelected: false},
        {code: '#79FDF9', isSelected: false},
        {code: '#FFFFFF', isSelected: false},
        {code: '#000000', isSelected: false},
        {code: '#38B0DE', isSelected: false},
        {code: '#F2473F', isSelected: false},
        {code: '#b400ff', isSelected: false},
        {code: '#6600FF', isSelected: false},
        {code: '#3A5894', isSelected: false},
        {code: '#2F2F4F', isSelected: false},
        {code: '#174038', isSelected: false},
        {code: '#fffa00', isSelected: false},
        {code: '#c39e0a', isSelected: false},
        {code: '#9dcff9', isSelected: false},
        {code: '#E0F12E', isSelected: false},
        {code: '#69808F', isSelected: false},
        {code: '#CD1F2D', isSelected: false},
        {code: '#B85E68', isSelected: false},
        {code: '#000152', isSelected: false},
        {code: '#a9a9de', isSelected: false},
        {code: '#1295BA', isSelected: false},
        {code: '#ff4000', isSelected: false},
    ],
    hours: 0,
    minutes: 0,
    jobTypeCategoreis: [],
    searchForCategory: '',
    checklist: [],
    searchForJobType: '',
    jobTypeList: [],
    newjobTypeId: 0
  }
},
 created() {
},
computed: {
    formattedMinutes () {
      const remainder = this.minutes % 15;
      return this.minutes - remainder;
    },
    color: {
      get () {
        return this.saveJobTypeObject.jobTypeColor
      },
      set (v) {
        this[this.type] = v
      }
    }
  },
mounted () {
  this.$root.$on('confirmBoxHandler', (response) => {
    document.body.style = 'overflow: visible;'
    if (response) {
        this.deleteApi()
      }
    this.removeConfirm = false
  })
  this.getJobCategoryList()
  if (this.jobTypeId > 0) {
    if (this.jobTypeDetail !== null) {
      this.saveJobTypeObject.jobTypeId = this.jobTypeDetail.jobTypeId
      this.saveJobTypeObject.jobTypeName = this.jobTypeDetail.jobTypeName
      this.saveJobTypeObject.jobCategoryId = this.jobTypeDetail.jobCategoryId
      this.saveJobTypeObject.jobCategoryName = this.jobTypeDetail.jobCategoryName
      this.saveJobTypeObject.jobTypeShortCode = this.jobTypeDetail.jobTypeShortCode
      this.saveJobTypeObject.jobTypeColor = this.jobTypeDetail.jobTypeColor
      this.saveJobTypeObject.typeEstimationMinutes = this.jobTypeDetail.typeEstimationMinutes
      this.saveJobTypeObject.jobCheckList = this.jobTypeDetail.jobCheckList
      this.saveJobTypeObject.displayOrder = this.jobTypeDetail.displayOrder
      this.saveJobTypeObject.IsDefault = this.jobTypeDetail.IsDefault
      this.saveJobTypeObject.isActive = this.jobTypeDetail.isActive
      this.saveJobTypeObject.organizationDetailId = this.jobTypeDetail.organizationDetailId

      this.hours = Math.floor(this.jobTypeDetail.typeEstimationMinutes / 60)
      this.minutes = this.jobTypeDetail.typeEstimationMinutes % 60

      if (this.jobTypeDetail.jobCheckList !== null || this.jobTypeDetail.jobCheckList !== "null") {
        let jobCheckList = JSON.parse(this.jobTypeDetail.jobCheckList)
        for (let index = 0; index < jobCheckList.length; index++) {
          let obj = {
            checkLiTitle: jobCheckList[index].checkLiTitle,
            showPlus: false
          }
          this.checklist.push(obj)
        }
        this.checklist[this.checklist.length - 1].showPlus = true
      }
    }
  }
},
watch: {},
methods: {
  addItem() {
    this.checklist.push({ checkLiTitle: "", showPlus: true });
    if (this.checklist.length > 1) {
      this.checklist[this.checklist.length - 2].showPlus = false;
    }
  },
  removeItem(index) {
    this.checklist.splice(index, 1);
    if (index === this.checklist.length && this.checklist.length > 0) {
      this.checklist[this.checklist.length - 1].showPlus = true;
    }
  },
  getJobCategoryList () {
  this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
    MyJobApp.JobCategoryList(
        response => {
          this.jobTypeCategoreis = response.Data !== null ? response.Data : []
           if (this.jobTypeId > 0 && this.jobTypeDetail !== null) {
            this.jobTypeCategoreis.forEach(element => {
              if (element.jobCategoryId === this.jobTypeDetail.jobCategoryId)
              this.searchForCategory = element.categoryName
            })
           }
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        },
        () => {
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        }
      )
  },
  selectedCategory (data) {
    if (data !== null) {
      console.log('select note cate data', data)
      this.searchForCategory = data.categoryName
      this.saveJobTypeObject.jobCategoryId = data.jobCategoryId
    } else {
      this.searchForCategory = ''
      this.saveJobTypeObject.jobCategoryId = 0
    }
  },
  selectedJobType (data) {
    if (data !== null) {
      console.log('select note cate data', data)
      this.searchForJobType = data.jobTypeName
      this.newjobTypeId = data.jobTypeId
    } else {
      this.searchForJobType = ''
      this.newjobTypeId = 0
    }
  },
  incrementHours() {
    this.hours++;
    this.saveJobTypeObject.typeEstimationMinutes = (this.hours * 60 ) + this.minutes
  },
  decrementHours() {
    if (this.hours > 0) {
      this.hours--;
    }
    this.saveJobTypeObject.typeEstimationMinutes = (this.hours * 60 ) + this.minutes
  },
  incrementMinutes() {
    if (this.minutes < 45) {
      this.minutes += 15;
      this.saveJobTypeObject.typeEstimationMinutes = (this.hours * 60 ) + this.minutes
    } else {
      this.minutes = 0;
      this.incrementHours();
    }
  },
  decrementMinutes() {
    if (this.minutes > 0) {
      this.minutes -= 15;
      this.saveJobTypeObject.typeEstimationMinutes = (this.hours * 60 ) + this.minutes
    } else {
      if (this.hours > 0) {
        this.minutes = 45;
        this.decrementHours();
      }
    }
  },
  addedColor(data) {
    for (let index = 0; index < this.colorList.length; index++) {
      if (this.colorList[index].code === data.code) {
        this.colorList[index].isSelected = true
        this.saveJobTypeObject.jobTypeColor = data.code
      } else {
        this.colorList[index].isSelected = false
      }    
      this.showColorPicker = false
    }
    data.isSelected = true
  },
  discard () {
    document.body.style = 'overflow: visible;'
    this.$root.$emit('addNewJobTypeComponennt')
  },
  SaveJobTypeData () {
    let checklist = []
    for (let index = 0; index < this.checklist.length; index++) {
      let obj = {
        checkLiTitle: this.checklist[index].checkLiTitle
      }
      checklist.push(obj)
    }
    this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
      MyJobApp.SaveJobTypeDetail(
      this.saveJobTypeObject,
      checklist,
      response => {
        this.$root.$emit('addNewJobTypeComponennt', true)
        this.$store.dispatch('SetLoader', {status: false, message: ''})
        this.$store.dispatch('SetAlert', {showAlert: true, message: response.message, color: 'success'})
      },
      () => {
        this.$store.dispatch('SetLoader', {status: false, message: ''})
      }
     )
  },
  checkJobTypeLinkORNot () {
      MyJobApp.CheckJobTypeLinked(
      this.jobTypeId,
      response => {
        if (response.Data > 0) {
          console.log('sds')
          this.callJobTypeListAPI()
        } else {
          this.removeConfirm = true
        }
        this.$store.dispatch('SetLoader', {status: false, message: ''})
      },
      () => {
        this.$store.dispatch('SetLoader', {status: false, message: ''})
      }
     )
  },
  callJobTypeListAPI () {
    this.jobTypeList = []
    this.alernateRemoveConfirm = true
      MyJobApp.GetJobTypeList(
          response => {
            if (response.Data !== null) {
              response.Data.forEach(element => {
                if (element.jobTypeId !== this.jobTypeId) {
                 this.jobTypeList.push(element)
                }
              })
            } else {
              this.jobTypeList = []
            }
            console.log('jobTypeList', this.jobTypeList)
            this.$store.dispatch('SetLoader', {status: false, message: ''})
          },
          () => {
            this.$store.dispatch('SetLoader', {status: false, message: ''})
          }
        )
  },
  deleteApi () {
    this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
      MyJobApp.DeleteJobType(
      this.jobTypeId,
      this.newjobTypeId,
      response => {
        console.log('response', response);
        this.$root.$emit('addNewJobTypeComponennt', true)
        this.$store.dispatch('SetLoader', {status: false, message: ''})
        this.$store.dispatch('SetAlert', {showAlert: true, message: response.message, color: 'success'})
      },
      () => {
        this.$store.dispatch('SetLoader', {status: false, message: ''})
      }
     )
  }
},
beforeDestroy () {
  this.$root.$off('confirmBoxHandler')
}
}
</script>
<style scoped>
.custom_dialog_with_overflow_radius {
  margin: 70px auto;
  background: #fff;
  position: relative;
  border-radius: 50px!important;
}
</style>